import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Card from "components/card";
import useFeeds from "components/hooks/useFeeds";
import { slicedFeeds, mq } from "utils/helper";
import { jsx } from "@emotion/core";
import { rhythm, scale } from "utils/typography";
import LocalizedLink from "components/localizedLink";
import { colors, H2 } from "style/theme";
import ClipLoader from "react-spinners/ClipLoader";
import { any } from "ramda";
import { isTrue } from "utils/helper";


const ArchiveUpdates = () => {
  const feeds = useFeeds();

  let content = [];
  for (let i = 0; i < 4; i++) {
    content = [...content, ...feeds.map(({ feed }) => feed?.data?.item?.[i]).filter(item => !!item)];
    if (content.length > 4) break;
  }

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "space-around",
        marginTop: rhythm(3),
        flexWrap: "wrap",
        [mq[0]]: {
          justifyContent: "space-between",
        },
      }}
    >
      {content.length <= 0 && (
        <ClipLoader
          size={150}
          color={colors.primary}
          loading={true}
        />
      )}
      {content.slice(0,4).map(item => <Card key={item.guid} node={item} />)}
    </div>
  )
}

function Updates() {
  const latestContent = useStaticQuery(graphql`
    query HeaderQuery {
      allMdx(
        filter: {
          fileAbsolutePath: { regex: "/content/" }
          frontmatter: { draft: { ne: true }, featured: { eq: true } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            id
            fields {
              slug
              locale
            }
            frontmatter {
              title
              date
              desc
              image
            }
          }
        }
      }
    }
  `);
  const latestArticle = latestContent.allMdx.edges.map((n) => n.node);
  return (
    <div
      css={{
        maxWidth: "1200px",
        margin: `${rhythm(6)} auto 0 auto`,
        [mq[1]]: {
          marginTop: rhythm(3),
        },
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <H2
          css={{
            maxWidth: "unset",
            [mq[1]]: {
              marginLeft: rhythm(1),
            },
          }}
        >
          Recent updates
        </H2>
        <h4
          css={{
            color: colors.primary,
            margin: 0,
            [mq[1]]: {
              display: "none",
            },
          }}
        >
          <LocalizedLink
            to="news"
            css={{ color: colors.primary, ":hover": { color: colors.light } }}
          >
            MORE +
          </LocalizedLink>{" "}
        </h4>
      </div>
      <div
        css={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: rhythm(3),
          flexWrap: "wrap",
          [mq[0]]: {
            justifyContent: "space-between",
          },
        }}
      >
        {latestArticle.map((node) => <Card node={node} key={node.id} />)}
      </div>
      <ArchiveUpdates />
    </div>
  );
}

export default Updates;
